import { useMemo } from 'react';
import {
  getPromotionBannerEndDate,
  getPromotionBannerText,
  isValidPromo,
} from 'src/components/PromotionBanner/promotionUtils';
import { PromotionBanners } from 'src/queries/groq/promotionBanners';
import { DefaultBannerOptions, SanityBanner } from 'src/typings/sanityTypes';

export const usePromotionBannerData = (
  country: string,
  promotionBanners: PromotionBanners,
  countryHasBeenSet: boolean
) => {
  const promotionData = useMemo<SanityBanner | undefined>(() => {
    if (!countryHasBeenSet) return;

    if (promotionBanners && promotionBanners.length > 0) {
      // Find the first active promo for the given country:
      return promotionBanners.find(
        (banner) => banner?.countries?.includes(country) && isValidPromo(banner as SanityBanner, banner.endDate)
      ) as SanityBanner | undefined;
    }
  }, [promotionBanners, country, countryHasBeenSet]);

  const bannerOptions = useMemo<DefaultBannerOptions | undefined>(() => {
    if (!promotionData) return;
    if (promotionData.countrySpecificText) {
      return getPromotionBannerText(promotionData.countrySpecificText, promotionData.bannerOptions, country);
    }

    return promotionData.bannerOptions;
  }, [promotionData, country]);

  const promoEndDate = useMemo<string | undefined>(() => {
    if (!promotionData) return;
    if (promotionData.customEndDate) {
      return getPromotionBannerEndDate(promotionData.customEndDate, promotionData.endDate, country);
    }

    return promotionData.endDate;
  }, [promotionData, country]);

  return {
    promotionData: promotionData,
    promoEndDate: promoEndDate,
    bannerOptions: bannerOptions,
  };
};
