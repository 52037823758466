import { Types } from '@remarkable/rm-store-types';
import React, { useContext, useMemo } from 'react';
import { DeviceVariant, FolioVariant, MarkerVariant } from 'src/data/productData';
import { getBundleSku } from 'src/helpers/MPFCartHelpers';
import { useCountryData } from 'src/hooks/useCountryData';

export const EPPromoContext = React.createContext<Types.Store.Promotion[]>([]);

export type WithEpPromos = {
  epPromos: Types.Store.Promotion[];
};

const getDiscountByCurrency = (
  currency: string,
  discounts:
    | {
        currencies: {
          amount?: number;
          currency: string;
        }[];
      }
    | undefined
) => {
  if (discounts) {
    const discount = discounts?.currencies.find((discount) => discount.currency === currency);
    if (discount && discount.amount) {
      return {
        amount: discount.amount,
        currency: discount.currency,
      };
    }
  }
};

export const useElasticPathPromoByBundleSku = (
  device: DeviceVariant,
  marker: MarkerVariant,
  folio: FolioVariant
):
  | {
      amount: number;
      currency: string;
    }
  | undefined => {
  const promos = useContext(EPPromoContext);
  const { currency } = useCountryData();

  const promotionTitleForBundleSku = {
    BUNDLE_RM110_RM212_RM312: 'TEMPLATE:BW22 Bundle Leather',
    BUNDLE_RM110_RM212_RM313: 'TEMPLATE:BW22 Bundle Leather',
    BUNDLE_RM110_RM212_RM314: 'TEMPLATE:BW22 Bundle Polymer',
  };

  if (promos.length === 0) {
    throw Error('useElasticPathPromo did not find any promos, are we outside a EPPromoContext?');
  }

  const bundleSku = getBundleSku(device, marker, folio);

  const bundlePromo = useMemo(
    () => promos.find((promo) => promo.name.includes(promotionTitleForBundleSku[bundleSku])),
    [promos, bundleSku]
  );

  if (bundlePromo && bundlePromo.enabled) {
    return getDiscountByCurrency(currency, bundlePromo.schema);
  }
};

export const useElasticPathPromo = (
  name: 'CthulhuDevice' | 'CthulhuMarker' | 'CthulhuFolio', // <- update type here if promo names changes
  currency: string
):
  | {
      amount: number;
      currency: string;
    }
  | undefined => {
  const promos = useContext(EPPromoContext);
  if (promos.length === 0) {
    throw Error('useElasticPathPromo did not find any promos, are we outside a EPPromoContext?');
  }
  const promo = useMemo(() => promos.find((promo) => promo.name === name), [promos, name]);
  if (promo && promo.enabled) {
    return getDiscountByCurrency(currency, promo.schema);
  }
};
