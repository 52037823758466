import { Image } from '@remarkable/ark-web';
import React from 'react';
import { SanityCountry } from 'src/typings/sanityTypes';

type ButtonProps = {
  country: SanityCountry;
  onClick: () => void;
};

export const CountryButton: React.FunctionComponent<ButtonProps> = ({ country, onClick }) => (
  <li className="list-none">
    <button className="flex p-8 mb-8" type="button" onClick={onClick}>
      <Image
        src={country.flag?.asset?.url}
        className="mr-8 align-bottom"
        height="20"
        width="20"
        alt=""
        loading="lazy"
      />
      {country.label}
    </button>
  </li>
);
