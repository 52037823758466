import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCartVisible } from 'src/redux/actions/storeActions2';

/**
 * This is very old code, and it's unclear whether it's in use. Maybe this should be removed.
 */
export const useVisibleCartOnRouteParam = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  useEffect(() => {
    const cart = router.query['cart'];
    if (cart === 'true') {
      dispatch(setCartVisible(true));
    }
  }, [router.query]);
};
