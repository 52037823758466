import { Typography } from '@remarkable/ark-web';
import classNames from 'classnames';
import { groupBy } from 'lodash';
import React from 'react';
import { CountryButton } from 'src/components/CountrySelector';
import { SanityCountry } from 'src/typings/sanityTypes';

type Props = {
  continent: string;
  countryData: SanityCountry[];
  onCountryChange: (selectedCountry: string) => void;
  classnames?: string;
};

const ShippingCountrySection: React.FC<Props> = ({ continent, countryData, onCountryChange, classnames }) => {
  const countries = groupBy(countryData, 'continent');

  return (
    <section>
      <Typography as="h2" variant="heading-xs" className="text-24 font-book mt-0">
        {continent}
      </Typography>
      <ul className={classNames('[border-left:_2px_solid_#dddddd] pt-8 pr-0 pb-0 pl-16', classnames)}>
        {countries[continent].map((it: SanityCountry) => (
          <CountryButton key={it.label} country={it} onClick={() => onCountryChange(it.value)} />
        ))}
      </ul>
    </section>
  );
};

export default ShippingCountrySection;
