import cx from 'classnames';
import { Countdown } from 'src/components/Countdown/Countdown';

import { PromotionBannerProps } from './promotionBannerTypes';

interface CenterPromotionBannerProps extends PromotionBannerProps {
  headingText: string;
  headingTextAfterBreak: string;
  subtitleText: string;
}

export const CenterPromotionBanner = ({
  headingText,
  headingTextAfterBreak,
  subtitleText,
  bannerTheme,
  endDate,
  withCountDown,
  showOnDesktop,
  showOnMobile,
}: CenterPromotionBannerProps): JSX.Element | null => {
  return (
    <div
      data-promotion-banner-theme={bannerTheme}
      className={cx(
        'promotion-banner-container flex-col items-center lg:mx:auto justify-center lg:w-full z-10 lg:inset-x-0 m-auto p-24 lg:px-0 font-sans text-center',
        showOnMobile ? 'flex' : 'hidden',
        showOnDesktop ? 'md:flex' : 'md:hidden'
      )}
    >
      {headingText && (
        <div className="flex flex-row items-center justify-center my-4 font-serif font-book text-32 mb-24 md:mb-16">
          <span>-</span>
          <span className="px-8 mx-8 md:hidden">
            {headingText} <br /> {headingTextAfterBreak}
          </span>
          <span className="hidden px-12 mx-12 md:inline-block">
            {headingText} {headingTextAfterBreak}
          </span>
          <span>-</span>
        </div>
      )}
      <span className="px-16 md:px-32 font-medium tracking-widest uppercase text-14">
        <span className="hidden md:inline-block">
          {subtitleText} {withCountDown && <Countdown fontSize={24} endDate={new Date(endDate)} />}
        </span>
        <div className="md:hidden">
          <p className="mb-4">{subtitleText} </p>
          {withCountDown && <Countdown fontSize={20} endDate={endDate} />}
        </div>
      </span>
    </div>
  );
};
