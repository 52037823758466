import { CustomBannerText, CustomEndDate, DefaultBannerOptions, SanityBanner } from 'src/typings/sanityTypes';

const checkPromoInDateRange = (startTimeDate: Date, endTimeDate: Date) => {
  return endTimeDate.getTime() > Date.now() && startTimeDate.getTime() < Date.now();
};

export const isValidPromo = <
  R extends {
    showOnMobile: boolean;
    showOnDesktop: boolean;
  }
>(
  sanityBanner: SanityBanner,
  endDate: string,
  promoAttributes?: R | null
): promoAttributes is R => {
  return !!(
    sanityBanner.activePromo &&
    checkPromoInDateRange(new Date(sanityBanner.startDate), new Date(endDate)) &&
    (!promoAttributes || promoAttributes?.showOnDesktop || promoAttributes?.showOnMobile)
  );
};

export function isValidPromoForCountry(countries: string[], currentCountryValue: string): boolean {
  return countries.includes(currentCountryValue);
}

export function getPromotionBannerEndDate(
  customEndDates: CustomEndDate[],
  defaultEndDate: string,
  currentCountryValue: string
) {
  const countrySpecificEndDate = customEndDates.find((item) => item.countries.includes(currentCountryValue));
  return countrySpecificEndDate ? countrySpecificEndDate.endDate : defaultEndDate;
}

// Get country specific text - otherwise returns banner option text.
export function getPromotionBannerText(
  countrySpecificBannerTexts: CustomBannerText[],
  bannerOptions: DefaultBannerOptions,
  currentCountryValue: string
): DefaultBannerOptions {
  const countrySpecificText = countrySpecificBannerTexts.find((item) => item.countries.includes(currentCountryValue));

  if (countrySpecificText) {
    const getTopBanner = (banner: string) => ({
      ...bannerOptions[banner],
      topBannerText: countrySpecificText[banner]?.topBannerText ?? bannerOptions[banner].topBannerText ?? '',
    });

    const getCenterBanner = (banner: string) => ({
      ...bannerOptions[banner],
      headingText: countrySpecificText[banner]?.headingText ?? bannerOptions[banner].headingText ?? '',
      headingTextAfterBreak:
        countrySpecificText[banner]?.headingTextAfterBreak ?? bannerOptions[banner].headingTextAfterBreak ?? '',
      subtitleText: countrySpecificText[banner]?.subtitleText ?? bannerOptions[banner].subtitleText ?? '',
    });

    return {
      mainPageTop: bannerOptions['mainPageTop'] ? getTopBanner('mainPageTop') : null,
      mainPageCenter: bannerOptions['mainPageCenter'] ? getCenterBanner('mainPageCenter') : null,
      productPageTop: bannerOptions['productPageTop'] ? getTopBanner('productPageTop') : null,
      productPageCenter: bannerOptions['productPageCenter'] ? getCenterBanner('productPageCenter') : null,
    };
  }
  return bannerOptions;
}
