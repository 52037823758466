import React from 'react';

type Props = {
  targetId: string;
  forwardedRef?: React.Ref<HTMLAnchorElement>;
  onClick?: (e: React.MouseEvent) => void;
} & React.HTMLAttributes<HTMLAnchorElement>;

export const scrollTo = (targetId: string): void => {
  const node = document.querySelector(`#${targetId}`);

  if (node) {
    node.scrollIntoView({ behavior: 'smooth' });
  } else {
    console.warn(`Could not find element with matching id (#${targetId}) in document`);
  }
};

const ScrollAnchor: React.FC<Props> = ({ targetId, forwardedRef, children, onClick, ...rest }) => {
  const scroll = (e: React.MouseEvent) => {
    history.pushState(null, '', `#${targetId}`);

    const element = document.getElementById(targetId);
    if (!element) {
      console.error(`Could not scroll to ${targetId}. Element not found`);
      return;
    }

    e.preventDefault();
    element.scrollIntoView({ behavior: 'smooth' });

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <a href={`#${targetId}`} onClick={scroll} ref={forwardedRef} {...rest}>
      {children}
    </a>
  );
};

export default ScrollAnchor;

export const ScrollAnchorWithRef = React.forwardRef((props: Props, ref: React.Ref<HTMLAnchorElement>) => (
  <ScrollAnchor {...props} forwardedRef={ref} />
));
