import cx from 'classnames';
import { Countdown } from 'src/components/Countdown/Countdown';

import { PromotionBannerProps } from './promotionBannerTypes';

export interface TopPromotionBannerProps extends PromotionBannerProps {
  topBannerText: string;
}

export const TopPromotionBanner = ({
  topBannerText,
  showOnDesktop,
  showOnMobile,
  withCountDown,
  bannerTheme,
  endDate,
}: TopPromotionBannerProps): JSX.Element | null => {
  return (
    <div
      id="notification-bar"
      data-cy="notification-bar"
      data-promotion-banner-theme={bannerTheme}
      className={cx(
        'promotion-banner-container relative min-h-[56px] overflow-hidden z-30 flex items-center justify-center',
        showOnMobile ? 'flex' : 'hidden',
        showOnDesktop ? 'md:flex' : 'md:hidden'
      )}
    >
      <div className="text-14 text-center px-8">
        <p className="font-medium tracking-widest uppercase md:block">
          <span>
            {topBannerText}
            {withCountDown && (
              <span className="ml-4">
                <Countdown fontSize={14} endDate={endDate} />
              </span>
            )}
          </span>
        </p>
      </div>
    </div>
  );
};
