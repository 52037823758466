import { connect, ConnectedProps } from 'react-redux';
import * as countryDetectorActions from 'src/redux/actions/countryDetectorActions';
import { State } from 'src/redux/reducers';

import NoShippingModal from './NoShippingModal';

const mapStateToProps = (state: State) => ({
  show: state.noShippingModal.show,
  isLoading: state.noShippingModal.isLoading,
  success: state.noShippingModal.success,
  country: state.noShippingModal.country,
  countryData: state.staticQuery.countryData,
});

const mapDispatchToProps = {
  changeCountry: countryDetectorActions.changeCountry,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export interface NoShippingModalProps extends PropsFromRedux {
  showCountries?: boolean;
}

export default connector(NoShippingModal);
