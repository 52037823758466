import { CaretCircleDown } from 'phosphor-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'src/components/Modal';
import { getCountryNameForCode } from 'src/helpers/formattingHelpers';
import * as noShippingModalActions from 'src/redux/actions/noShippingModalActions';
import { eventActionEnum, eventCategoryEnum, pushGAEventToDataLayer } from 'src/services/googleTagManager';

import { Button, Typography } from '@remarkable/ark-web';
import classNames from 'classnames';
import SFSignupForm from 'src/components/MarketingOptIn/shared/SFSignupForm';
import { twMerge } from 'tailwind-merge';
import { NoShippingModalProps } from './index';
import ShippingCountrySection from './ShippingCountrySection';

const NoShippingModal = (props: NoShippingModalProps) => {
  const { show, country, changeCountry } = props;
  const dispatch = useDispatch();
  const [showCountries, setShowCountries] = useState(props.showCountries ? true : false);

  const location = getCountryNameForCode(country);

  const onCountryChange = (selectedCountry: string) => {
    pushGAEventToDataLayer(eventCategoryEnum.PAGE_INTERACTION, eventActionEnum.SELECT_YOUR_LOCATION, selectedCountry);
    changeCountry(selectedCountry);
    dispatch(noShippingModalActions.show({ show: false }));
  };

  return (
    <Modal show={show} handleClose={() => dispatch(noShippingModalActions.show({ show: false }))} mobilePositionStart>
      <div className="flex flex-col justify-center items-center mt-[60px] mx-48 mb-48">
        <Typography as="h2" variant="heading-md" className="text-center">
          Sorry! reMarkable doesn't ship to {location ?? 'your current location'} yet
        </Typography>
        <Typography as="p" variant="body-md-book" className="my-40 text-center">
          Sign up to be notified when we do.
        </Typography>

        <SFSignupForm leadSource="No Shipping Modal" />

        <Button
          onClick={() => setShowCountries(!showCountries)}
          className="bg-transparent hover:bg-transparent border-none mt-40 text-grayscale-gray-800"
        >
          <CaretCircleDown
            size={30}
            weight="light"
            className={classNames('align-middle', { 'rotate-180 duration-500': showCountries })}
          />
          <Typography as="span" variant="interface-md-book" className="text-grayscale-gray-800">
            Alternative shipping countries
          </Typography>
        </Button>

        <div
          className={twMerge(
            'flex flex-col md:flex-row mt-40 overflow-y-scroll w-full md:[transition:_max-height_0.5s_ease-in-out]',
            `${showCountries ? 'max-h-full md:max-h-[500px]' : 'max-h-0'}`
          )}
        >
          <div className="mr-32 flex flex-col justify-between">
            <ShippingCountrySection
              continent="North America"
              countryData={props.countryData}
              onCountryChange={onCountryChange}
            />
            <ShippingCountrySection
              continent="Asia"
              countryData={props.countryData}
              onCountryChange={onCountryChange}
            />
            <ShippingCountrySection
              continent="Oceania"
              countryData={props.countryData}
              onCountryChange={onCountryChange}
            />
          </div>
          <ShippingCountrySection
            continent="Europe"
            countryData={props.countryData}
            onCountryChange={onCountryChange}
            classnames="md:columns-2 lg:columns-4"
          />
        </div>
      </div>
    </Modal>
  );
};

export default NoShippingModal;
